#authorizelogin {
    display: flex;
    flex-direction: column;
    background-color: #EEF2F7;
}

#authorize {
    background-color: #EEF2F7;
    scroll-behavior: smooth;
}

#authorizelogin .ant-checkbox-inner {
    background-color: transparent !important;
    border: 1px solid rgba(168, 171, 178, 1) !important;
}

#authorizelogin .ant-checkbox-checked .ant-checkbox-inner {
    border: 0 !important;
    background-color: rgba(62, 126, 248, 1) !important;
}

#authorize .ant-checkbox-inner {
    background-color: transparent !important;
    border: 1px solid rgba(168, 171, 178, 1) !important;
}

#authorize .ant-checkbox-checked .ant-checkbox-inner {
    border: 0 !important;
    background-color: rgba(62, 126, 248, 1) !important;
}

.content {
    margin-top: 3%;
}

.emailtitle {
    font-size: 16px;
    background-color: rgba(242, 244, 250, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cloud {
    width: 24px;
    height: 20px;
    margin-right: 4px;
}


.custom-label {
    font-weight: bold;
}

#emailbox .ant-input-number-handler-wrap {
    display: none;
}

#authorize::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}