@layer base {
  /* 这里是字体相关 */
  /* .win {
    --font-family: 'Source Han Sans', 'Microsoft YaHei', inter, Roboto !important;
  }

  .mac {
    --font-family: PingFang, inter, Roboto !important;
  } */

  .theme-dark {
    /* 主题色 */
    --color-primary: #19a07b;
    /* 整体背景色 */
    --color-allBackground: #131517;
    /* 版块背景色 */
    --color-modulesBackground: #202329;
    /* 下拉背景色 */
    --color-dropdownBackground: #262930;
    /* 中性灰（其余背景色） */
    --color-otherBackground: #1c1e24;
    /* 顶部导航背景色 */
    --color-menuTopBackground: #202329;
    /* 重点文字颜色 */
    --color-textMain: #e4e9f2;
    /* 次要文字颜色 */
    --color-textMinor: #909399;
    /* 辅助文字颜色 */
    --color-textAssist: #6c6e73;
    /* 索引文字颜色 */
    --color-textInfo: #5f6166;
    /* 禁用文字颜色 */
    --color-textDisable: #393a3d;
    /* 分割线颜色 */
    --color-dividingLine: #2a2e38;
    /* 表单选中色颜色 */
    --color-selectedColor: #2e323b;
    /* 表单色颜色 */
    --color-tableColor: #272b32;
    /* 表单表头色颜色 */
    --color-tableHeader: #292c33;
    /* 图标背景色 */
    --color-iconBackground: #323640;
    /* 分割块背景色 */
    --color-splitBlockBackground: #272b32;
    /* 默认按钮颜色 */
    --color-defaultButtonBg: #1d2025;
    /* 辅助按钮颜色 */
    --color-assistButtonBg: #1b2c2a;
    /* 禁用按钮颜色 */
    --color-disableButtonBg: #2f4d49;
    /* 禁用radio选中颜色 */
    --color-disableCheckedRadioBg: rgb(28 110 90);
    /* 悬浮按钮颜色 */
    --color-hoverButtonBg: #1b7157;
    /* 超链接颜色 */
    --color-colorLink: #4c77fe;
    /* 自供电svg背景色 */
    --color-selfPowerBackground: #323640;

    /* 成功色 */
    --color-successColor: #21b878;
    /* 成功色背景 */
    --color-successBg: #1b2c2a;

    /* 错误色 */
    --color-errorColor: #ef4444;
    /* 错误色背景 */
    --color-errorBg: #2e2327;

    /* 警告色 */
    --color-warnColor: #f67234;
    /* 警告色背景 */
    --color-warnBg: #2b2524;

    /* 次要色 */
    --color-secondaryColor: #f9b233;
    /* 次要色背景 */
    --color-secondaryBg: #2f2c26;

    /* 进行色 */
    --color-blueColor: #416efe;
    /* 进行色背景 */
    --color-blueBg: #242b3d;

    /* 排队色 */
    --color-purpleColor: #8774FB;
    /* 进行色背景 */
    --color-purpleBg: #F0EEFB;

    /* 辅助色 */
    --color-grayColor: #6e7280;
    /* 辅助色背景 */
    --color-grayBg: #2f3239;

    /* 流动线的颜色 */
    --color-flowLine: #6acbca;

    /* 绿色色系 */
    --color-green-300: #1aa369;
    --color-green-100: #2f4d49;
  }

  .theme-light {
    /* 主题色 */
    --color-primary: #3e7ef8;
    /* 整体背景色 */
    --color-allBackground: #eef2f7;
    /* 版块背景色 */
    --color-modulesBackground: #ffffff;
    /* 顶部导航背景色 */
    --color-menuTopBackground: #2c2c2c;
    /* 下拉背景色 */
    --color-dropdownBackground: #fff;
    /* 中性灰（其余背景色） */
    --color-otherBackground: #f8f8fa;
    /* 重点文字颜色 */
    --color-textMain: #18191a;
    /* 次要文字颜色 */
    --color-textMinor: #515559;
    /* 辅助文字颜色 */
    --color-textAssist: #9da6ad;
    /* 索引文字颜色 */
    --color-textInfo: #b6bfc9;
    /* 禁用文字颜色 */
    --color-textDisable: #ccd1d9;
    /* 分割线颜色 */
    --color-dividingLine: #e2e8f0;
    /* 表单选中色颜色 */
    --color-selectedColor: #f3f4f6;
    /* 表单色颜色 */
    --color-tableColor: #f5f5f7;
    /* 表单表头色颜色 */
    --color-tableHeader: #e6e8ec;
    /* 图标背景色 */
    --color-iconBackground: #e6e8ec;
    /* 分割块背景色 */
    --color-splitBlockBackground: #f5f5f7;
    /* 默认按钮颜色 */
    --color-defaultButtonBg: #fff;
    /* 辅助按钮颜色 */
    --color-assistButtonBg: #e5ebfe;
    /* 禁用按钮颜色 */
    --color-disableButtonBg: #cbd7fe;
    /* 禁用radio选中颜色 */
    --color-disableCheckedRadioBg: rgb(139 178 251);
    /* 悬浮按钮颜色 */
    --color-hoverButtonBg: #98b1fe;
    /* 超链接颜色 */
    --color-colorLink: #4c77fe;
    /* 自供电svg背景色 */
    --color-selfPowerBackground: #eff7ff;

    /* 成功色 */
    --color-successColor: #21b878;
    /* 成功色背景 */
    --color-successBg: #e8fff1;

    /* 错误色 */
    --color-errorColor: #ef4444;
    /* 错误色背景 */
    --color-errorBg: #fce8e8;

    /* 警告色 */
    --color-warnColor: #f67234;
    /* 警告色背景 */
    --color-warnBg: #fff5e5;

    /* 次要色 */
    --color-secondaryColor: #f9b233;
    /* 次要色背景 */
    --color-secondaryBg: #fff5e3;

    /* 进行色 */
    --color-blueColor: #416efe;
    /* 进行色背景 */
    --color-blueBg: #e5ebfe;

    /* 排队色 */
    --color-purpleColor: #8774FB;
    /* 进行色背景 */
    --color-purpleBg: #F0EEFB;

    /* 辅助色 */
    --color-grayColor: #6e7280;
    /* 辅助色背景 */
    --color-grayBg: #f0f2fa;

    /* 流动线的颜色 */
    --color-flowLine: #1d8ff8;

    /* 绿色色系 */
    --color-green-300: #21b878;
    --color-green-100: #a4e2c8;
  }

  /* 一些无法在tailwind配置 也无法在antd配置的样式 */
  body {
    /* 富文本编辑器相关 */
    /* 内容区域背景色 */
    --w-e-textarea-bg-color: var(--color-modulesBackground);
    /* 内容区域文字颜色 */
    --w-e-textarea-color: var(--color-textMain);
    /* 工具栏背景色 */
    --w-e-toolbar-color: var(--color-textMain);
    /* 工具栏文字颜色 */
    --w-e-toolbar-bg-color: var(--color-modulesBackground);
    /* 工具栏border颜色 */
    --w-e-toolbar-border-color: var(--color-dividingLine);
    /* 工具栏点击时背景色 */
    --w-e-toolbar-active-bg-color: var(--color-selectedColor);
    /* 工具栏悬停时的弹出框背景色 */
    --w-e-toolbar-active-color: var(--color-selectedColor);
  }

  .w-e-menu-tooltip-v5:before {
    /* 工具栏悬停时弹出框文字颜色 */
    color: var(--color-textMain) !important;
  }

  .w-e-bar-item button:hover {
    /* 工具栏悬停时文字颜色 */
    color: var(--color-textMain) !important;
  }

  /* radio及checkbox无法在配置文件中设置 所以在这里写入 */
  .ant-checkbox-wrapper {
    color: var(--color-textAssist) !important;
  }

  .layout-container .ant-checkbox-checked .ant-checkbox-inner {
    border: 0 !important;
    background-color: var(--color-primary) !important;
  }

  .ant-transfer-list-header {
    display: none !important;
  }

  .layout-container .ant-checkbox-inner {
    border: 1px solid var(--color-textInfo) !important;
    /* background-color: transparent !important; */
    border-radius: 2px !important;
  }

  .ant-checkbox-wrapper-checked .ant-checkbox-disabled .ant-checkbox-inner {
    border: 0 !important;
    background-color: var(--color-disableButtonBg) !important;
    color: #fff;
  }

  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: var(--color-textDisable) !important;
  }

  .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
    border-color: var(--color-textDisable) !important;
  }

  .ant-checkbox-wrapper-checked .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: var(--color-cMainTxt) !important;
  }

  .ant-checkbox-wrapper-checked {
    color: var(--color-textMain) !important;
  }

  .ant-radio-wrapper {
    color: var(--color-textAssist) !important;
    margin-right: 8px !important;
  }

  .ant-radio-wrapper-checked {
    color: var(--color-textMain) !important;
  }

  .ant-radio-inner {
    border: 1px solid var(--color-textInfo) !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border: 1px solid var(--color-disableCheckedRadioBg) !important;
  }

  /* 这里是editor的单独样式写入 */
  .ql-snow {
    border: 1px solid var(--color-dividingLine) !important;
  }

  /* 这里是upload的filelist单独写入 */
  .ant-upload-list-item-name {
    color: var(--color-blueColor) !important;
  }

  /* 这里是select多选选中的tag标签的文字颜色 */
  .ant-select-selection-item-content {
    color: var(--color-primary) !important;
  }

  .ant-select-selection-item-remove {
    color: var(--color-primary) !important;
  }

  /* 这里是步骤跳的颜色配置 */
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: #fff !important;
  }

  .ant-steps-item-finish .ant-steps-item-tail::after {
    background-color: var(--color-primary) !important;
  }

  .ant-steps-item-finish .ant-steps-item-title::after {
    background-color: var(--color-primary) !important;
  }

  .ant-steps-item-active .ant-steps-item-tail::after {
    background-color: var(--color-dividingLine) !important;
  }

  .ant-steps-item-active .ant-steps-item-title::after {
    background-color: var(--color-dividingLine) !important;
  }

  .ant-steps-item-wait .ant-steps-item-tail::after {
    background-color: var(--color-dividingLine) !important;
  }

  .ant-steps-item-wait .ant-steps-item-title::after {
    background-color: var(--color-dividingLine) !important;
  }

  /* 这里是超出宽度省略号的颜色配置 */
  /* .ant-table-cell-ellipsis {
    color: #4C77FE;
  } */
  /* 这里是分页器的颜色配置 */
  .ant-pagination-item {
    border: 1px solid var(--color-dividingLine) !important;
  }

  .ant-pagination-item-active a {
    color: var(--color-textMain) !important;
  }

  .ant-table-pagination {
    background: var(--color-modulesBackground);
    /* border-top: 1px solid var(--color-dividingLine); */
  }

  /* ant divider颜色 */
  .ant-divider {
    background: var(--color-dividingLine);
  }

  /* select图标颜色 */
  .ant-select-arrow {
    color: var(--color-textAssist) !important;
  }

  /* 以下是menu菜单下svg图标和文字颜色 */
  .ant-menu-item-selected svg path {
    fill: var(--color-primary);
  }

  .ant-menu-overflow-item .ant-menu-title-content {
    color: #fff !important;
  }

  .ant-menu-submenu-horizontal .ant-menu-title-content {
    color: #fff !important;
  }

  .ant-menu-submenu-selected .ant-menu-title-content {
    color: #fff !important;
  }

  .ant-menu-overflow-item .ant-menu-title-content:hover {
    color: var(--color-primary) !important;
  }

  .ant-menu-submenu-horizontal .ant-menu-title-content:hover {
    color: var(--color-primary) !important;
  }

  .ant-menu-submenu-selected .ant-menu-title-content:hover {
    color: #fff !important;
  }

  .ant-menu-item-selected.ant-menu-item-only-child .ant-menu-title-content:hover {
    color: #fff !important;
  }

  .ant-menu-submenu .ant-menu-submenu-title:hover {
    color: var(--color-primary) !important;
  }

  .ant-menu-item:hover {
    color: var(--color-primary) !important;
  }

  .ant-menu-item:hover .ant-menu-item-icon svg path {
    fill: var(--color-primary);
  }

  .ant-menu-submenu {
    padding: 0 16px !important;
  }

  /* 日期图标颜色 */
  .ant-picker-suffix {
    color: var(--color-textAssist) !important;
  }

  .ant-select-selector {
    box-shadow: none !important;
  }

  /* tabs配置 */
  .ant-tabs-tab-active svg path {
    fill: var(--color-primary) !important;
  }

  .ant-tabs-tab svg path {
    fill: var(--color-textInfo);
  }

  .ant-tabs-tab-active span {
    color: var(--color-primary) !important;
  }

  .ant-tabs-tab span {
    fill: var(--color-textMinor);
  }

  /* 滑块配置 */
  /* .ant-switch {
    background-color: var(--color-textDisable);
  }
  .ant-switch-checked {
    background-color: var(--color-primary);
  } */
  /* .ant-switch:hover {
    background: var(--color-dividingLine) !important;
  } */
  .ant-switch:not(.echartsConfig .ant-switch):hover {
    background: var(--color-dividingLine) !important;
  }

  .ant-switch-checked:not(.echartsConfig .ant-switch-checked):hover {
    background: var(--color-hoverButtonBg) !important;
  }

  /* 表格排序 */
  .ant-table-column-sorter-inner svg path {
    fill: var(--color-textAssist) !important;
  }

  .ant-table-column-sorter-inner .active svg path {
    fill: var(--color-primary) !important;
  }

  /* select tree */
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--color-primary) !important;
    border: 0 !important;
  }

  .ant-select-tree-checkbox-inner {
    border: 1px solid var(--color-textInfo) !important;
    background-color: transparent !important;
  }

  /* datepicker 这个是时间日期范围选取后的背景 */
  .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: var(--color-primary) !important;
    color: #fff !important;
  }

  .ant-picker-time-panel-column::-webkit-scrollbar {
    display: none;
  }

  .ant-transfer-list::-webkit-scrollbar {
    display: none;
  }

  /* 弹出框背景色 */
  .ant-modal-content {
    background-color: var(--color-modulesBackground) !important;
  }

  .ant-alert {
    border: 0 !important;
  }

  /* 这里是一些过渡动画修改，ui不想要 */
  .ant-table-tbody tr td,
  .ant-table-thead tr th {
    transition: border-color 0s !important;
  }

  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-select-selector,
  .ant-radio-inner,
  .ant-btn {
    transition: all 0s !important;
  }

  .ant-input,
  .ant-input-number,
  .ant-menu-item,
  input {
    transition: all 0s !important;
  }

  /* 这个是用在登陆页的 */
  .login input:-webkit-autofill,
  .login input:-webkit-autofill:hover,
  .login input:-webkit-autofill:focus {
    transition: all 0s !important;
    -webkit-box-shadow: 0 0 0 1000px var(--color-dropdownBackground) inset !important;
    -webkit-text-fill-color: var(--color-textMain);
    -webkit-transition-delay: 99999s;
    -webkit-transition:
      color 99999s ease-out,
      background-color 99999s ease-out;
    caret-color: var(--color-textMain);
  }

  .loginInput .ant-input {
    height: 48px !important;
  }

  /* 这个是用在全局的浏览器填充的密码那些东西的 */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: all 0s !important;
    -webkit-box-shadow: 0 0 0 1000px var(--color-modulesBackground) inset !important;
    -webkit-text-fill-color: var(--color-textMain);
    -webkit-transition-delay: 99999s;
    -webkit-transition:
      color 99999s ease-out,
      background-color 99999s ease-out;
    caret-color: var(--color-textMain);
  }

  /* 调整提示框距离顶部的位置 */
  .ant-message {
    top: 30vh !important;
  }

  .customPicker input {
    text-align: center;
  }

  /** Form.Item tooltip 问号图标颜色 */
  .ant-form-item-tooltip {
    color: var(--color-textAssist) !important;
  }

  /* .ant-table-column-sorters {} */

  /* .ant-table-column-title {
    flex: 0 !important;
    white-space: nowrap;
  }

  .ant-table-column-sorter {
    flex: 1 !important;
  } */

  /* 调整排序图标与文本的间距
  .ant-table-column-sorter-inner {
    margin-left: 2px !important;
  } */

  .rc-virtual-list-scrollbar-thumb {
    background-color: var(--color-textDisable) !important;
  }
}

.runtimeBox:hover svg path {
  stroke: var(--color-primary);
}

.ant-input-outlined:focus,
.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  box-shadow: none !important;
}

/* input hover的时候也出现了阴影border颜色不对*/
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
  border-color: #ff4d4f;
}

/* input 输入框输入的时候error 状态的边框颜色会出现阴影 */
span.ant-input-affix-wrapper.ant-input-outlined.ant-input-status-error {
  box-shadow: none !important;
}

.homeSpower .ant-select-selection-item,
.ant-picker-input {
  color: var(--color-textMain);
}

.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  border-radius: 2px;
  border-color: var(--color-textAssist) !important;
}

/* tabWarpper 的边框渐变 */
.tabDiv {
  /* border-image: linear-gradient(45deg, #E2E8F0,#EEF2F7) 1;  */
  border: 1px solid var(--color-dividingLine);
}

.ant-select-tree-node-selected {
  color: var(--color-primary) !important;
}

/* 全局input select等设置高度统一36px */
/* .ant-input-number, */
.ant-select,
.ant-input,
.ant-picker {
  height: 36px !important;
}

/* H5授权页面账号输入框高度与Web其他地方不一致*/
#authorizelogin .oauth-acount .ant-select,
#authorizelogin .oauth-acount .ant-input,
#authorizelogin .oauth-acount .ant-picker {
  height: 48px !important
}

.ant-input-outlined input {
  height: 26px !important;
}

/* 只有首页与其余地方高度不同 */
.home-child-container .ant-input-outlined input {
  height: 30px !important;
}

/* 忘记密码页面与其余页面高度不同 */
.forgot-password .ant-input-outlined input {
  height: 34px !important;
}

.device-view-container {
  display: flex;
  column-gap: 22px;
  /* 默认 gap 值 */
}

@media (min-width: 1700px) {
  .device-view-container {
    column-gap: 108px;
    /* 大于 1600px 时的 gap 值 */
  }
}

/* 覆盖横向布局下的 Label 固定宽度 */
/* 根据动态计算的宽度设置 Label */
.ant-form-horizontal .formItemCustomWidth .ant-form-item-label {
  width: var(--label-max-width);
  /* 应用统一宽度 */
}



.appUpgradeNotification .ant-checkbox-inner {
  border-color: var(--color-textDisable) !important;
}

/* 告警列表sn太多了需要出现滚动 */
.alertListSnTooltip .ant-tooltip-inner {
  max-height: 300px;
  overflow: scroll;
}