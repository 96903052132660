@config "../tailwind.config.ts";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('../../common/style/base.css');
@import url('../../common/style/global.css');
@import url('../../common/style/font.css');

@layer base {
  .theme-default {
    --color-primary1: 50 50 50;
  }
}

#child-app>div {
  height: 100%;
  width : 100%;
}