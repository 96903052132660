/* 开关容器 */
.switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* 开关样式 */
.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
}

/* 隐藏默认的 checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* 滑块的背景 */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

/* 滑块的圆形按钮 */
.slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* 当开关打开时，改变背景颜色和滑块位置 */
input:checked+.slider {
    background-color: rgba(62, 126, 248, 1);
}

input:checked+.slider:before {
    transform: translateX(12px);
}