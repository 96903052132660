.mapBoxNoFull {
  height: calc(100% - 1px);
  width: 100%;
}

/* 1080P分辨率样式 */
@media (max-width: 1920px) {
  .mapBoxIsFull {
    top: 3.78rem;
  }
}
/* 2K分辨率样式 */
@media (max-width: 2560px) and (min-width: 1921px) {
  .mapBoxIsFull {
    top: 60px;
  }
}

.mapBoxIsFull {
  position: absolute !important;
  left: 0px;
  height: calc(100% - 60px);
  width: 100vw;
  z-index: 999;
}
.marker {
  position: relative;
}
.marker::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 0;
  border-top: 9px solid #3e7ef8;
  bottom: -10px;
  right: 7px;
}
.getTooltipContent {
  position: relative;
}
.getTooltipContent::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 0;
  border-top: 9px solid var(--color-modulesBackground);
  bottom: -8px;
  right: 54%;
}
#mapBox .gm-fullscreen-control {
  display: none;
}
#mapBox .gm-bundled-control {
  display: none;
  top: 0px;
  right: 60px;
}
#mapBox .gm-bundled-control .gmnoprint div {
  display: flex;
}
.tooltipContent svg path:hover {
  fill: rgb(var(--color-primary));
}
.shadow-div {
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  background: var(--color-modulesBackground);
  border-radius: 4px;
}
.shadow-div-button {
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
}
.amap-info {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* 1080P分辨率样式 */
@media (max-width: 1920px) {
  .numberMarker {
    margin-top: 0.3rem;
  }
}
/* 2K分辨率样式 */
@media (max-width: 2560px) and (min-width: 1921px) {
  .numberMarker {
    margin-top: 0.1rem;
  }
}
