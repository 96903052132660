.bck {
  background-image: url("../../assets/bgc.png");
  background-repeat: 'repeat';
  width: 100%;
  display: flex;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;


}

.Stein {
  background-image: url('../../assets/Stein.png');
  height: 100px;
  width: 100px;
  background-size: cover;
}

.Atmoce {
  background-image: url('../../assets/Atmoce.png');
  height: 100px;
  width: 100px;
  background-size: cover;
  margin-bottom: 16px;
}

.box {
  display: flex;
  flex-direction: column;
}

.des {
  color: #606266;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.secBox {
  width: 90%;
  display: flex;
  margin-left: 5%;
  flex-direction: row;
  position: relative;
}

.deviderLine {
  height: 1px;
  background-color: #EAEBED;
  width: 90%;
  margin-left: 5%;
  margin-top: 16px;
  margin-bottom: 16px
}

.downtype {
  background-image: url('../../assets/Apple.png');
  height: 46px;
  width: 46px;
  background-size: contain;
  margin-right: 20px;
}

.downtype1 {
  background-image: url('../../assets/GoolePlay.png');
  height: 46px;
  width: 46px;
  background-size: contain;
  margin-right: 20px;
}

.downtype2 {
  background-image: url('../../assets/Browser.png');
  height: 46px;
  width: 46px;
  background-size: contain;
  margin-right: 20px;
}

.btns {
  color: #EE884D;
  width: 54px;
  border-radius: 10px;
  border: 1px solid #EE884D;
  text-align: center;
  position: absolute;
  padding-top: 1px;
  padding-bottom: 1px;
  right: 0;
  top: 6px;
  font-size: 12px;
  user-select: none;
}

.modelType {
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 18px;
  color: #18191A;
}