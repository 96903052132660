#emailbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.content {
    margin-top: 3%;
    /* max-width: 60%; */
}

.emailtitle {
    font-size: 16px;
    background-color: rgba(242, 244, 250, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cloud {
    width: 24px;
    height: 20px;
    margin-right: 4px;
}


.custom-label {
    font-weight: bold;
}

#emailbox .ant-input-number-handler-wrap {
    display: none;
}